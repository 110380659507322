import { SvgIcon } from '@material-ui/core';
import * as React from 'react';

export interface ItsaMatchIconProps {
  className?: string;
  opacity: number;
}

export const ItsaMatchIcon: React.SFC<ItsaMatchIconProps> = ({
  className,
  opacity
}) => (
  <SvgIcon
    className={className}
    fontSize='inherit'
    titleAccess="It's a Match!"
    viewBox='0 0 576.95 160.67'
    style={{
      opacity
    }}
  >
    <path d='M386.71,7.47c9.42,5.36,12.31,10,8.9,20.45a175.17,175.17,0,0,0-5.56,87.38,62.52,62.52,0,0,0,5.76,16.4C403.61,147.16,419.71,152,435,144c2.89-1.52,5.57-3.43,9.69-6-6.69,14.2-14.36,22.19-31.44,22.71-10.83.33-23.45-6.49-29.43-23.63-10.22-29.31-8-59.49-5.4-89.64.21-2.35.55-4.69-.47-7.34C373,56.6,368.33,73.24,363.07,89.69a236.11,236.11,0,0,1-10.64,27.79c-1.52,3.28-5.68,7.44-8.59,7.39-2.73,0-6.94-4.67-7.81-8-2.65-10.2-5-20.68-5.65-31.17-.91-15.54-.23-31.17-2.13-47-3.42,12.4-6.32,25-10.4,37.16s-9.11,24.37-14.33,36.27a70,70,0,0,1-9.34,14.72c-11.88,15.12-26.56,19.71-39.33,12.71C241.67,132.35,235.72,115,241.45,98c2.47-7.33,6.61-14.09,10-21.1,1-1.94,3.68-1.22,2.6,1.18s-2.53,4.63-3.61,7c-5.42,12.15-6.6,24.43-.64,36.75,6.71,13.86,20.81,17.51,32.1,7.08a92.22,92.22,0,0,0,18-23.43c15.32-28.26,23.31-59.1,30.35-91.44-21.35.74-41.82,2.53-59.69,13.78-5.32,3.36-11.45,11.26-10.92,16.43s8.16,12.41,13.92,13.88c11.86,3,24.54,2.79,35.22,3.75-11.05,12.3-44.07,12-56.29-2.75-7-8.42-6-18.51,3.34-27.45,12.21-11.7,27.85-15.92,43.9-18.34,11.15-1.68,22.52-1.86,33.78-2.78,8.51-.69,11.39,3.19,11.24,11.59-.46,25.7-.43,51.42-.31,77.14,0,4.56,1.43,9.12,2.2,13.69l3.24.78c2.33-4.92,5.26-9.65,6.91-14.78,7.72-24.13,15-48.4,22.65-72.56C381.36,20.34,384,14.41,386.71,7.47Z' />
    <path d='M.6,121.35C1.45,119.5,28.2,110,40.12,108,37.45,93.83,38.73,80.52,40,66.67c1.23-13.56,3.53-27.36,5.41-41.23C34.69,26.15,19,27.36,9,21.17a1.38,1.38,0,0,1,.84-2.55C32.12,21.83,58.32,10.93,80.07,8.1c9.37-1.21,12.38.9,16.08,9.59-13-.69-23.59,3.1-35.16,5.4-2.62.52-4.66.79-6.54,1.1-.47,25.33-.33,57.13-.33,81.64,11.5-.33,29.39,5.93,32.95,10s.88,5.37-1.37,5.87S60.76,119,46.2,119.5c-14.08.47-41.34,8.71-44,7.5S-.26,123.2.6,121.35Z' />
    <path d='M447.2,62.5c-14.15-.64-16.5-4-16.93-14-.08-1.8,7,3.17,18.47,1.71,1.46-7.42,2.81-14.25,4.17-21.13a1.24,1.24,0,0,1,1.56-.94c5.41,1.55,8.6,3.29,8.81,4.36.34,1.67-2.53,12.94-3.5,17.67,5.5.46,18.84-3.27,20.5-3a1.85,1.85,0,0,1,1.19,3C479,53.44,462.7,59.75,457.7,61c-4.24,21.47-7.7,46.44-.36,68.12l-2.41,1.27c-3-4.86-7.73-13.51-8.85-19.64-2.75,5.38-9.38,9.56-13.7,8.44-3.63-1-6-6.56-9.33-10.51-.66.86-2.11,3.2-3.35,4.82-2.8,3.66-8,6.21-11.05,6-4.2-.26-7.29-2.71-9.2-7.34-3.5-8.5-1.79-28.78,6.25-40.42,7.3-10.57,15.92-10.38,23.25.13,2.44,3.48,5,2.86,5.63,5.5.77,3.23-.33,8.14-.72,11.84-.55,5.1-1.07,10.34-.42,15.36.4,3.13,3,6,4.63,9,2.55-2.84,6.51-5.4,7.3-8.52C445.45,90,445,76.5,447.2,62.5Zm-42.75,40c.13,5.72,1.23,10.14,4.25,11.5s8.34-6.55,9.5-9.5c3.1-7.84,3.15-13.19,4.75-21.5.44-2.28,1-5.94-2.06-8.53s-9.5,2.46-10.44,4.53C406.85,87,404.32,96.78,404.45,102.5Z' />
    <path d='M507.87,124.64c-4.42-.64-6.11-7.15-5.85-10.48,2.32-30.72,2.57-61.74,11.23-91.67A76.23,76.23,0,0,1,519,7.15c1.58-2.92,4.1-7.1,7.69-7.15,4.56-.06,6.77,6.44,7.33,9.78a56.65,56.65,0,0,1-2.1,25.34,147,147,0,0,1-13,30c-1.56,2.81-4.29,7.06-6.79,10.72-.5,3.84-1.58,10.65-2.42,16.8,3.25-5.25,10.07-16,14-20.93,1.69-2.09,5.92-3.63,8.31-3.13,2.91.6,3.69,5,3.68,7.71,0,8.9-1.16,17.78-1.34,26.68-.07,3.53-.78,10.54,2.43,13,3,2.3,9-2.7,10-7.89.51-2.56,2.14-1.7,2.09-.06-.21,7-6.21,12.88-12.38,13-7.63.09-10.57-5.94-11.4-12.22-1.22-9.28-1.31-18.71-2-29.33-10.3,13.6-12.49,28.13-11.75,43.5C511.58,125,509.45,125,507.87,124.64ZM528,19.83c-.67-8.16-3.56-6.22-5-2.33s-8.33,22-10.83,53C522.78,54,528.85,30.88,528,19.83Z' />
    <path d='M101.08,53c4.55,0,10-1.18,14.64-1.18,0,.78.12,1.31,0,1.43-5.25,4.5-12.25,7.25-16.25,8.5-2,14.5-11.25,51.25-4,53.25,3.41.94,10.2-6.07,12.29-10,6.73-12.64,12.43-25.81,18.8-38.65,1.88-3.8,4.65-7.15,6.56-10,3.37,19.93,7,38.79,9.43,57.8.49,3.79-2.28,10-5.42,11.73s-9.89,1.09-12.93-1.26a15.41,15.41,0,0,1-5.73-11.34c-.06-4.15,1.45-3.62,2-2.47s2.91,9.58,8.85,8-.36-26.25-.79-39.35C121,91.13,114.71,102.07,107.13,112c-2.91,3.82-10.16,8.43-13,7.18-4.11-1.78-8.77-8.09-9.5-12.94-1.92-12.58-.13-27.5,1.93-43.12-12.65-.78-16.81-3.88-14.06-12.38,4,2.75,9.25,2.75,15.28,2.74,1.55-9,3-15.66,4.46-20.91,4.09,1.09,10.43,2.42,11.09,5.09C103.9,40.13,102.44,45.69,101.08,53Z' />
    <path d='M197.2,105.75c-4.25,11.5-12.63,14.22-17.75,13.36-9.16-1.52-10.69-11.57-9.4-19.16,1.79-10.49,4.1-20.14,10.15-28.7,5.09-7.18,13.75-10.75,19.5-1.75,1.79,2.8,2.11,6.38,4.25,7.75,6.25,4,2.73,8.83,2.29,13.51-.39,4.09-1.07,8.25-.64,12.27.38,3.48.41,9.09,3.3,10.2s9.19-3.54,9.69-4c1-.91,2.3-.82,1.83,1.05s-7,8.46-12.47,9.21C198.66,120.77,198,112.5,197.2,105.75Zm-20.91-2.81c-1.34,5.81,2.42,9.73,4.83,10.54,3.83,1.27,7.74-4.7,9.12-8.07a103.48,103.48,0,0,0,5.86-22.55c.42-2.61,0-7-3.2-8.54s-7.51,2.59-8.63,5.08A146.47,146.47,0,0,0,176.29,102.94Z' />
    <path d='M486.67,72.92c-4.72-3.25-18.72,12.41-14,32.2.93,3.87,3.82,9.67,9.56,10.28s11.4-6.73,10.4-12.73a17.89,17.89,0,0,0-2.92-6.84c-1.42-2.08-.64-3.93,1.44-3.14,3.48,1.31,8.14,9,6.14,18-1.59,7.16-9.69,12.42-16.83,11.58-4.25-.5-11-4.25-14-9.25-7.82-13.24-2.29-40.81,11.06-48.51,3.23-1.87,8.06-3.48,11.88-.67a13.33,13.33,0,0,1,4.92,14.61,10,10,0,0,1-7.11,7.32c-1.87.38-3-1.12-2.37-2.87C485.28,81.61,491.39,76.17,486.67,72.92Z' />
    <path d='M563.56,92.26V7.71C571.69,12.36,575.62,16,577,20.33c-2.89,19.23-7.47,70-8.33,72.67C567.63,94.63,564.93,94.22,563.56,92.26Z' />
    <path d='M123.07,51.68C123,48,124.7,34,119.77,25.46c-1-1.69.43-5.21,5-6.17a5.82,5.82,0,0,1,5.52,5.36c1.42,13.1-2.45,24.65-5.11,27.73A1.16,1.16,0,0,1,123.07,51.68Z' />
    <path d='M572.7,111c-.6,5-4.62,6.73-8.68,6.37s-6.3-3.78-6.07-8.12c.25-4.75,3.51-8.09,8.5-7.5C570.7,102.25,573.3,106,572.7,111Z' />
  </SvgIcon>
);
