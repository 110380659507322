import { SvgIcon } from '@material-ui/core';
import * as React from 'react';

export enum ChevronIcons {
  backArrow = 'backArrow',
  nextArrow = 'nextArrow'
}

export interface ChevronIconProps {
  iconType?: ChevronIcons;
}

export const ChevronIcon: React.SFC<ChevronIconProps> = ({ iconType }) => {
  switch (iconType) {
    case ChevronIcons.backArrow:
      return (
        <SvgIcon
          fontSize='inherit'
          titleAccess='Back'
          viewBox='0 0 29.46 39.52'
        >
          <path d='M28.47,31.57a5.44,5.44,0,0,1,1,3.08,4.76,4.76,0,0,1-.83,2.79,4.83,4.83,0,0,1-2.26,1.77,5.24,5.24,0,0,1-3.32.08,7.43,7.43,0,0,1-2.72-1.4q-1.23-1-2.46-1.89Q15,33.87,12.2,31.73c-1.87-1.42-3.76-2.85-5.67-4.27a19.79,19.79,0,0,0-2-1.4,16.62,16.62,0,0,1-2.26-1.69A7.9,7.9,0,0,1,.53,22.28,4.05,4.05,0,0,1,0,19.73,5.19,5.19,0,0,1,1.8,16.4c1-.79,2-1.6,3.08-2.42q2.79-2.14,5.55-4.36T16,5.18q1.64-1.31,3.33-2.87A8.92,8.92,0,0,1,23.13.17a4.7,4.7,0,0,1,3,.17,4.5,4.5,0,0,1,2,1.72,5.66,5.66,0,0,1,.87,2.63,5.18,5.18,0,0,1-.62,2.88,6.32,6.32,0,0,1-1.19,1.6,15.79,15.79,0,0,1-1.73,1.48c-.63.47-1.23.89-1.81,1.27l-1.43,1c-1.54,1.21-3.06,2.38-4.57,3.53l0,0a4.2,4.2,0,0,0,.1,6.72c1,.75,2,1.51,3,2.28q3.66,2.88,7.36,5.67Z' />
        </SvgIcon>
      );
    case ChevronIcons.nextArrow:
    default:
      return (
        <SvgIcon
          fontSize='inherit'
          titleAccess='Next'
          viewBox='0 0 29.46 39.52'
        >
          <path d='M1,8A5.44,5.44,0,0,1,0,4.87,4.76,4.76,0,0,1,.82,2.08,4.82,4.82,0,0,1,3.08.31,5.27,5.27,0,0,1,6.41.23,7.52,7.52,0,0,1,9.12,1.62c.82.66,1.65,1.29,2.47,1.89,1.92,1.43,3.81,2.85,5.67,4.28s3.75,2.85,5.67,4.27a20.72,20.72,0,0,0,2,1.4,16,16,0,0,1,2.26,1.68,8.14,8.14,0,0,1,1.73,2.1,4,4,0,0,1,.49,2.55,5.22,5.22,0,0,1-1.76,3.33c-1,.79-2,1.6-3.09,2.42C22.71,27,20.86,28.42,19,29.9s-3.69,3-5.55,4.43q-1.65,1.32-3.33,2.88a9.09,9.09,0,0,1-3.82,2.14,4.69,4.69,0,0,1-3-.17,4.58,4.58,0,0,1-2.06-1.72,5.69,5.69,0,0,1-.86-2.63A5.1,5.1,0,0,1,1,32a6.09,6.09,0,0,1,1.19-1.6A14.66,14.66,0,0,1,3.9,28.87c.63-.47,1.24-.89,1.81-1.28s1.06-.71,1.44-1c1.53-1.21,3.06-2.38,4.56-3.54l0,0a4.21,4.21,0,0,0-.1-6.72c-1-.75-2-1.51-3-2.29Q5,11.17,1.32,8.36Z' />
        </SvgIcon>
      );
  }
};
